// if IE 11 only
// @ts-ignore
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
if (msie > 0) {
  window.location.replace('ie.html');
} else {
  // @ts-ignore
  if (!!window.MSInputMethodContext && !!document.documentMode) {
    window.location.replace('ie.html');
  } else import('./main');
}
